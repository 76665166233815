/* RideHistory.css */

/* Table Styles */
.ridehistorytable {
    margin-top: 60px;
    padding-left: 20px;
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  table-layout: fixed; /* Set the table layout to fixed */
  white-space: nowrap; /* Prevent text wrapping within table cells */
  overflow-x: auto; /* Enable horizontal scrolling */
}

.ridehistorytable th,
.ridehistorytable td {
  border: none; /* Remove border for all cells */
  padding: 8px 10px; /* Horizontal padding of 10px and vertical padding of 8px */
  text-align: left;
}

.ridehistorytable th {
  background-color: #edca11;
  font-weight: bold;
  width: 160px; /* Fixed width for each column */
}

/* Hover Effect */
.ridehistorytable tbody tr:hover {
  background-color: #f5f5f5; /* Add your desired hover background color */
}

/* Animations (example: fade-in effect) */
.ridehistorytable tbody tr {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Additional styling for ridehistorybox */
.ridehistorybox {
  padding: 10px; /* Horizontal padding of 10px for the ridehistorybox */
  overflow-x: auto; /* Enable horizontal scrolling for the ridehistorybox */
}

/* Add borders between rows */
.ridehistorytable tbody tr {
  border-bottom: 1px solid #ddd;
}