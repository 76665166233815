/* Login.css */
*{
 margin: 0;
 padding: 0;
 font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image:url('./assets/banner.png');
    background-repeat: no-repeat;
    width: 100vw;
background-position:center;
background-size: cover;

  }
  
  .login-form {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    width: 350px;
    background-color: #f9f9f9;

  }
  
  .login-form h2 {
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #edca11;
    color: #000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;

  }
  
  button:hover {
    background-color: #00b321;
  }
  