.home{
    display: flex;
    .homeContainer{
        // background-color: lightblue;
        flex: 6;

        .widgets{
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            gap: 20px; // Adjust spacing between widgets
            padding: 20px;
        }

        .charts{
           padding: 5px; 
        //    display: flex;
        }

        .listContainer{
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.47);
            box-shadow: 2px 4px 10px 1px rgba(201,201,201,0.47);
            padding: 20px;
            margin: 20px;

            .listTitle{
                font-weight: 500;
                color: gray;
                margin-bottom: 15px;
            }
        }
    }
}