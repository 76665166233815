.sidebar{
    // background-color: lightcoral;
    border-right: 0.5px solid rgb(230, 225, 225);
    flex: 1;
    min-height: 100vh;
    min-width: 160px;

    .top{
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        border-radius: '10px';
        padding: '10px 5px';

        .logo{
            font-size: 20px;
            font-weight: bold;
            color: rgb(23, 208, 23);
          
            

        }
    }
      
    .mainlogocon{
        border-radius:'10px';
        background:#000;
    }




    hr{
        height: 0;
        border: 0.5px solid rgb(230, 225, 225);
    }

    .center{
        padding-left: 10px;

        ul{
            list-style: none;
            margin: 0;
            padding: 0;

            .title{
                font-size: 10px;
                font-weight: bold;
                color: #999;
                margin-top: 15px;
            }
            
            li{
                display: flex;
                align-items: center;
                padding: 5px;
                cursor: pointer;

                &:hover{
                    background-color: #ece8ff;
                }

                .icon{
                    font-size: 18px;
                    color:#000;
                }

                span{
                    font-size: 13px;
                    font-weight: 600;
                    color: #888;
                    margin-left: 10px;

                }
            }
            
        }
    }
}